import React, { FC, useState } from "react";
import MainNav2Logged from "./MainNav2Logged";
import { Alert } from "@mui/material";
import { useCollege } from "store/utils/hooks";
import { RichTextFormat } from "containers/ProductDetailPage/AccordionInfo";
import parse from 'html-react-parser';

export interface HeaderLoggedProps {}

const HeaderLogged: FC<HeaderLoggedProps> = () => {
  const college = useCollege();
  const [displayNotification, setDisplayNotification] = useState<boolean>(true)
  return (
    <div className="nc-HeaderLogged sticky top-0 w-full z-40 ">
      <MainNav2Logged />
      {college?.notification && displayNotification && <div style={{backgroundColor:'rgb(255, 244, 229)'}}> <Alert severity="warning" className="items-center container" onClose={()=>setDisplayNotification(false)}>
        <RichTextFormat>
          {parse(college?.notification)}
        </RichTextFormat>
      </Alert></div>}
    </div>
  );
};

export default HeaderLogged;
