import {TEN_YEARS} from 'constants/api';
import {ORDERS_TAG, baseApi} from './baseApi';
import { Order } from 'data/types';

const BASE_URL = 'orders';
const ordersApi = baseApi.injectEndpoints ({
  endpoints: builder => ({
    createOrder: builder.mutation ({
      query: body => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
      invalidatesTags: [ORDERS_TAG],
    }),
    getOrders: builder.query ({
      query: userId => `${BASE_URL}?user_id=${userId}?per_page=100`,
      keepUnusedDataFor: TEN_YEARS,
      providesTags: [ORDERS_TAG],
      transformResponse: (res:{
        data:Order[]
      }) => res.data?.data
    }),
    updateOrder: builder.mutation ({
      query: ({id, ...patch}) => ({
        url: BASE_URL + `/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: [ORDERS_TAG],
    }),
    createRefund: builder.mutation ({
      query: ({order, body}) => ({
        method: 'POST',
        url: `${order}/refunds`,
        body,
      }),
    }),
    confirmRedsys: builder.mutation({
      query:(body)=>({
        method:'POST',
        url:`redsys/webhook`,
        body,
      })
    }),
    retryPayment: builder.mutation({
      query:(id)=>({
        method:'POST',
        url:BASE_URL+'/retry-order/'+id,
      })
    })
  }),
});

export const {
  useCreateOrderMutation,
  useGetOrdersQuery,
  useCreateRefundMutation,
  useUpdateOrderMutation,
  useConfirmRedsysMutation,
  useRetryPaymentMutation,
} = ordersApi;
